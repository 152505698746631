import './App.css';
import OtterSpinner from './OtterSpinner';

function App() {
  return (
    <div className="App">
      <OtterSpinner />
    </div>
  );
}

export default App;
